const intialState = {
  loginData: [],
  changePassword: {
    message: "",
    error: null,
  },
  setPassword: {
    success: false,
    error: null,
  },
  error: null,
  forgotSuccessMessage: "",
  forgotError: null,
  loading: false,
};

export const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case "START_LOGIN_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCESS":
      return {
        ...state,
        loginData: action.data,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "SET_LOGOUT":
      return {
        ...state,
        loginData: {
          token: null,
          firstName: "",
          lastName: "",
          role: "",
          emailAddress: "",
          isActive: false,
          countryAccess: "",
          moduleAccess: [],
          userId: ""
        },
      };
    case "SETPASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        setPassword: {
          success: true,
          error: null,
        },
      };
    case "SETPASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        setPassword: {
          success: false,
          error: "",
        },
      };
    case "RESET_SETPASSWORD":
      return {
        ...state,
        loading: false,
        setPassword: {
          success: false,
          error: null,
        },
      };
    case "FORGOT_SUCCESS":
      return {
        ...state,
        forgotSuccessMessage: "Mail sent successfully",
        forgotError: null,
        loading: false,
      };
    case "FORGOT_FAILED":
      return {
        ...state,
        forgotSuccessMessage: "",
        loading: false,
        forgotError: action.error,
      };
    case "CHANGEPASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        changePassword: {
          message: "Password changed successfully",
          error: null,
        },
      };
    case "CHANGEPASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        changePassword: {
          message: "",
          error: "Error",
        },
      };
    case "CHANGEPASSWORD_RESET":
      return {
        ...state,
        changePassword: {
          message: "",
          error: null,
        },
      };
    case "RESET_ERROR_STATE":
      return {
        ...state,
        forgotSuccessMessage: "",
        forgotError: null,
      };
    default:
      return state;
  }
};