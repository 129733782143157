const defaultState = {
    videocall: true,
    isHost: true,
    isPinned: false,
    showSettingModal:false 
}

export default function callReducer(state = defaultState, action) {
    switch (action.type) {
        case "toggle_setting":
            return Object.assign({}, state, {
                showSettingModal:action.payload
            });
        
            case "change_layout":
            return Object.assign({}, state, {
                isPinned:action.payload
            });

            case "reset_state":
                return Object.assign({}, state, {
                    ...defaultState
                });
    
                case "join_call":
                    return Object.assign({}, state, {
                        videocall: true,
                    });
        
            case "call_end":
            return Object.assign({}, state, {
                videocall:action.payload
            });
        
        default:
            return state;
    }
}