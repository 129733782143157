import { RECEIVED_TABLES, RECEIVING_TABLES, RECEIVING_TABLE_ERROR } from "store/action/tableAction";

const defaultState = {
    tables: [],
    isReceiving: false,
    isUpdating: false,
    isDeleting: false,
    isError: false,
    errorMessage: '',
    idToDelete: null
}

export default function tablesReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVED_TABLES:
            return Object.assign({}, state, {
                tables: action.payload,
                isReceiving: false,
                isError: false,
                errorMessage: ''
            });
        case RECEIVING_TABLES:
            return Object.assign({}, state, {
                isReceiving: true,
                isError: false,
                errorMessage: ''
            });
        case RECEIVING_TABLE_ERROR:
            return Object.assign({}, state, {
                isReceiving: false,
                isError: true,
                errorMessage: action.payload
            });
        default:
            return state;
    }
}