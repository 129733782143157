import { buildSettings } from "../../buildSettings";

const intialState = {
  error: null,
  dashboardData: [],
  recentVisted: [],
  allDashboardData: [],
  // -----------watch all page---
  subCategories: [],
  isLodingWatchAll: false,

  selectedForum: [],
  othersForums: [],
  attending: [],
  attended: [],
  search: {
    searchResponse: [],
    loading: false,
  },
  loading: false,
  loadingVisitForam: false,
  isLodingAttending: false,
  isLodingPlanToAttend: false,
  isLodingBuyTicket: false,
};

export const dashboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "DASHBOARD_DATA_FETCH_SUCCESS":
      return {
        ...state,
        dashboardData: action.data,
        loading: false,
        error: null,
      };
    case "RECENT_VISITED_DATA_FETCH_SUCCESS":
      return {
        ...state,
        recentVisted: action.data,
        // loading: false,
        // error: null,
      };
    case "START_LOADING_WATCH_ALL":
      return {
        ...state,
        isLodingWatchAll: true,
      };
    case "FETCH_ALL_SUB_CATEGORIES":
      return {
        ...state,
        subCategories: action.data,
        isLodingWatchAll: false,
        error: null,
      };
    case "FETCH_ALL_DATA_OF_OTHERS":
      return {
        ...state,
        othersForums: action.data,
        loading: false,
        error: null,
      };

    case "WATCH_A_SINGLE_FORA":
      // window.location.reload()
      return {
        ...state,
        selectedForum: action.data,
        loadingVisitForam: false,
        error: null,
      };

    case "DASHBOARD_DATA_FETCH_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "START_SEARCH_LOADING":
      return {
        ...state,
        search: {
          loading: true,
          // searchResponse: [

          //          {
          //             "uid": "fe7c559f86baa2af",
          //             "name": "Post Lockdown Beauty Treatments",
          //             "subtitle": "Which ones Are You Looking To Book? ",
          //             "description": "Beauty bookings service Treatwell reported a 432% spike in bookings after salons were given a tentative reopening date for 12 April.\n\nAccording to Treatwell, hair appointments reigned supreme, representing 47% of bookings. Predictably, haircuts were the most popular type of hair bookings, with many of us desperate for a chop.\n\nWhat have you booked? ",
          //             "images": [
          //                 "https://storage.googleapis.com/see-surround.appspot.com/images/cf1dbd57-7e7e-4977-b8ac-00730a3d5930.JPEG"
          //             ],
          //             "sponsor_logo": null,
          //             "cover_image": "https://storage.googleapis.com/see-surround.appspot.com/images/d15780aa-c298-4844-8342-4a4049552c41.JPEG",
          //             "sponsor_link": null,
          //             "record": false,
          //             "private_room": false,
          //             "parent_category": "lifestyle",
          //             "max_people": 4,
          //             "max_channel": 10,
          //             "start_date": null,
          //             "end_date": null,
          //             "created_by": "RXZ92EyP86dfScrC3zXbRR19siP2",
          //             "tags": [],
          //             "is_visible": true,
          //             "created_on": 1615996108404,
          //             "deleted": false,
          //             "categories": [
          //                 "fashion"
          //             ],
          //             "next_recurrence": [
          //                 {
          //                     "start": 1684454400000,
          //                     "end": 1684540799000
          //                 },
          //                 {
          //                     "start": 1684540800000,
          //                     "end": 1684627199000
          //                 },
          //                 {
          //                     "start": 1684627200000,
          //                     "end": 1684713599000
          //                 },
          //                 {
          //                     "start": 1684713600000,
          //                     "end": 1684799999000
          //                 },
          //                 {
          //                     "start": 1684800000000,
          //                     "end": 1684886399000
          //                 },
          //                 {
          //                     "start": 1684886400000,
          //                     "end": 1684972799000
          //                 }
          //             ],
          //             "sub_packages": [
          //                 "com.seesurround.lecol"
          //             ],
          //             "format": "standard",
          //             "type": "audio-video",
          //             "recurrence": "daily",
          //             "callers": 0,
          //             "showInDashboard": true,
          //             "rspv": false,
          //             "favorite": false,
          //             "status": "active"
          //         }
          //     ],
          // searchResponse: state.search.searchResponse
          searchResponse: [
            {
              forum: {
                uid: "fe7c559f86baa2af",
                name: "Post Lockdown Beauty Treatments",
                subtitle: "Which ones Are You Looking To Book? ",
                description:
                  "Beauty bookings service Treatwell reported a 432% spike in bookings after salons were given a tentative reopening date for 12 April.\n\nAccording to Treatwell, hair appointments reigned supreme, representing 47% of bookings. Predictably, haircuts were the most popular type of hair bookings, with many of us desperate for a chop.\n\nWhat have you booked? ",
                images: [
                  "https://storage.googleapis.com/see-surround.appspot.com/images/cf1dbd57-7e7e-4977-b8ac-00730a3d5930.JPEG",
                ],
                sponsor_logo: null,
                cover_image:
                  "https://storage.googleapis.com/see-surround.appspot.com/images/d15780aa-c298-4844-8342-4a4049552c41.JPEG",
                sponsor_link: null,
                record: false,
                private_room: false,
                parent_category: "lifestyle",
                max_people: 4,
                max_channel: 10,
                start_date: null,
                end_date: null,
                created_by: "RXZ92EyP86dfScrC3zXbRR19siP2",
                tags: [],
                is_visible: true,
                created_on: 1615996108404,
                deleted: false,
                categories: ["fashion"],
                next_recurrence: [
                  {
                    start: 1684454400000,
                    end: 1684540799000,
                  },
                  {
                    start: 1684540800000,
                    end: 1684627199000,
                  },
                  {
                    start: 1684627200000,
                    end: 1684713599000,
                  },
                  {
                    start: 1684713600000,
                    end: 1684799999000,
                  },
                  {
                    start: 1684800000000,
                    end: 1684886399000,
                  },
                  {
                    start: 1684886400000,
                    end: 1684972799000,
                  },
                ],
                // sub_packages: ["com.seesurround.engfanzone"],
                sub_packages: buildSettings.packageId,
                format: "standard",
                type: "audio-video",
                recurrence: "daily",
                callers: 0,
                showInDashboard: true,
                rspv: false,
                favorite: false,
                status: "active",
              },
              users: [],
              status: "SUCCESS",
            },
          ],
        },
      };
    case "FETCH_SEARCH_DATA":
      return {
        ...state,
        search: {
          loading: false,
          searchResponse: action.data,
        },
      };
    case "FETCH_SEARCH_DATA_FAIL":
      return {
        ...state,
        search: {
          loading: false,
          searchResponse: [],
        },
      };
    case "SET_ATTENDING_DATA":
      return {
        ...state,
        attending: action.data,
        isLodingAttending: false,
        error: null,
      };
    case "START_LOADING_ATTENDING_FORAM":
      return {
        ...state,
        isLodingAttending: true,
        error: null,
      };

    case "START_LOADING_PLAN_TO_ATTEND":
      return {
        ...state,
        isLodingPlanToAttend: true,
        error: null,
      };

    case "END_LOADING_PLAN_TO_ATTEND":
      return {
        ...state,
        isLodingPlanToAttend: false,
        error: null,
      };
    case "START_LOADING_BUY_TICKET":
      return {
        ...state,
        isLodingBuyTicket: true,
        error: null,
      };

    case "END_LOADING_BUY_TICKET":
      return {
        ...state,
        isLodingBuyTicket: false,
        error: action.status == "success" ? null : "error",
      };
    case "GET_FORA_ATTENDED_HISTORY_DONE":
      return {
        ...state,
        attended: action.data,
        isLodingAttending: false,
        error: null,
      };
    case "GET_FORA_ATTENDED_HISTORY_FAILED":
      return {
        ...state,
        isLodingAttending: false,
        error: "Some thing went worng.",
      };

    case "START_LOADING_VISIT_A_FORAM":
      return {
        ...state,
        loadingVisitForam: true,
      };
    case "GET_COMBINED_DATA_DONE":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
