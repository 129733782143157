import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { authReducer } from "./reducer/authReducer";
import callReducer from "./reducer/callReducer";
import { dashboardReducer } from "./reducer/dashboardReducer";
import tablesReducer from "./reducer/tableReducer";
import { userProfileReducer } from "./reducer/userProfileReducer";

const appReducer = combineReducers({
  authentication: authReducer,
  userInfo: userProfileReducer,
  dashboard: dashboardReducer,
  tables: tablesReducer,
  callReducer: callReducer,
});

export const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// const persistor = persistStore(store);

// const getPersistor = () => persistor;
const getStore = () => store;
const getState = () => {
  return store.getState();
};

export { getStore, getState };
