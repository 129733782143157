const intialState = {
  error: null,
  categories: [],
  loading: false,
  notification: [],
  userByid: [],
  fora_user: [],
  credit: false,
  debit: false,
  updatedCredits: null,
  isLodingUBI: false,
  isUpdateCredit: false,
  // -----------------ROUND TABLE
  loadingCreateTable: false,
  //----------------------DASHBOARD USERS
};

export const userProfileReducer = (state = intialState, action) => {
  switch (action.type) {
    case "START_LOADING":
      return {
        ...state,
        loading: true,
        isLodingUBI: true,
      };

    case "CATEGORY_SUCCESS":
      console.log(action.data, "action.data");
      return {
        ...state,
        categories: action.data,
        loading: false,
        error: null,
      };
    // GET_USER_NOTIFICATION_DONE
    case "START_LOADING_USER_BY_ID":
      return {
        ...state,
        isLodingUBI: true,
      };
    case "FOLLOW_USER_DONE":
      return {
        ...state,
        loading: false,
        error: null,
      };

    case "FOLLOW_USER_FAILED":
      return {
        ...state,
        isLodingUBI: false,
        error: "Some thing went wrong",
      };

    case "USER_BY_ID":
      if (action.status == "purchase") {
        return {
          ...state,
          userByid: action.data,
          loading: false,
          credit: true,
          isLodingUBI: false,
          error: null,
        };
      }
      if (action.status == "buy") {
        return {
          ...state,
          userByid: action.data,
          debit: true,
          isLodingUBI: false,
          error: null,
        };
      }
      if (action.isForaUser) {
        console.log(action.isForaUser);

        return {
          ...state,
          fora_user: action.data,
          isLodingUBI: false,
          error: null,
        };
      }
      return {
        ...state,
        userByid: action.data,
        isLodingUBI: false,
        error: null,
      };

    case "CATEGORY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case "START_CREATING_ROUNDTABLE":
      return {
        ...state,
        loadingCreateTable: true,
      };
    case "END_CREATING_ROUNDTABLE":
      return {
        ...state,
        loading: false,
        loadingCreateTable: false,
      };
    case "GET_USER_NOTIFICATION_DONE":
      return {
        ...state,
        loading: false,
        notification: action.data,
        error: null,
        isLodingUBI: false,
      };
    case "GET_USER_NOTIFICATION_FAILED":
      return {
        ...state,
        loading: false,
        error: "some thing went worng.",
        isLodingUBI: false,
      };
    case "START_TIMER":
      return {
        ...state,
        isUpdateCredit: !state.isUpdateCredit,
      };
    case "UPDATE_TIMER":
      return {
        ...state,
        updatedCredits: action.data,
      };
    case "END_TIMER":
      return {
        ...state,
        isUpdateCredit: false,
      };
    default:
      return state;
  }
};
